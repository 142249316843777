import styled from 'styled-components';

import device from '@helpers/breakpoints';

const headerStyles = `
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
`;

const mobHeaderStyles = `
  left: 5px;
  transform: none;
  display: flex;
  flex-direction: column;
  max-width: 90px;
  font-size: 12px;
  line-height: 1.5;

  a {
    margin: 0;
  }
`;

export const Wrapper = styled.div<{ isHeader: boolean }>`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 14px;
  font-family: Roboto;

  ${({ isHeader }) =>
    isHeader
      ? headerStyles
      : 'margin: 40px 0 10px 0;padding: 0 16px;font-weight: bold;'}

  @media ${device.tablet} {
    ${({ isHeader }) => isHeader && mobHeaderStyles}
  }
`;

export const Number = styled.a<{ isHeader: boolean }>`
  margin-left: 5px;
  font-weight: normal;

  :hover {
    ${({ isHeader }) => (isHeader ? 'color: #c9be00;' : 'color: #005a6e;')}
  }

  ${({ isHeader }) =>
    isHeader ? 'color: #ffed00;font-weight: bold;' : 'color: #009cbc;'}
`;
