export const fieldHeight = {
  big: '48px',
  medium: '40px',
  small: '32px',
};

export const fonts = {
  big: '18px',
  medium: '16px',
  small: '14px',
};
