import React from 'react';
import Link from 'next/link';

import { useUserState } from '@providers/user';
import toggleStatusSidebar from '@providers/applicationContext/actions/toggleStatusSidebar';

import RoutesMapper from '../../../constants/routes';
import UserTypes from '../../../constants/userTypes';

import * as S from './Logo.styled';

const { ADMIN_OVERVIEW } = RoutesMapper;
const { USER } = UserTypes;

const Logo = (): JSX.Element => {
  const { data: user } = useUserState();

  return (
    <Link as={ADMIN_OVERVIEW} href={ADMIN_OVERVIEW}>
      <S.LogoLink className="logo" onClick={(): void => toggleStatusSidebar()}>
        <S.Logo src="/images/aircash.jpeg" />
        <S.LogoTitle>
          sw cash
          <S.Role> {user?.type === USER ? 'Admin' : 'Sub-Distributor'}</S.Role>
        </S.LogoTitle>
      </S.LogoLink>
    </Link>
  );
};

export default Logo;
