import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';
import ClickAwayListener from 'react-click-away-listener';
import padStart from 'lodash/padStart';
import noop from 'lodash/noop';
import { intervalToDuration } from 'date-fns';

import { useServices } from '@packages/services';

import Icon from '@components/common/iconComponents/Icon';
import SupportNumber from '@components/common/supportNumber/SupportNumber';

import { isDesktopView } from '@helpers/baseUtils';

import logoutUser from '@providers/user/actions/logoutUser';
import toggleStatusSidebar from '@providers/applicationContext/actions/toggleStatusSidebar';

import { TOKEN } from '../../../constants/cookies';
import config from '../../../aircashConfig';
import RoutesMapper from '../../../constants/routes';

import * as S from './CashierDashboardHeader.styled';

const { CASHIER_LOGIN } = RoutesMapper;

const CashierDashboardHeader = ({
  disabled,
  hideMenu,
}: {
  disabled: boolean;
  hideMenu?: boolean;
}): JSX.Element => {
  const { cookie } = useServices();
  const { push } = useRouter();
  const isDesktop = isDesktopView();
  const { loginedTime } = config[process.env.NODE_ENV || 'development'];
  const [time, setTime] = useState<number>(loginedTime);

  const formRemaining = (timer: number): string => {
    const timeLeft = intervalToDuration({
      start: timer,
      end: 0,
    });

    const hoursString = timeLeft.hours
      ? `${padStart(String(timeLeft.hours), 1, '0')}:`
      : '';
    const minutesString = padStart(String(timeLeft.minutes), 2, '0');
    const secondsString = padStart(String(timeLeft.seconds), 2, '0');

    return `${hoursString}${minutesString}:${secondsString}`;
  };

  const [remainingTime, setRemainingTime] = useState<string>(
    formRemaining(time),
  );

  const logout = (): void => {
    cookie.remove(TOKEN);
    logoutUser();
    push(CASHIER_LOGIN);
  };

  useEffect(() => {
    const timeout = setInterval((): void => {
      setRemainingTime(formRemaining(time));
      if (time >= 1) {
        setTime((prev) => prev - 1000);
      } else {
        logout();
      }
    }, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [time]);

  const handleStartCount = (): void => {
    setTime(loginedTime);
  };

  return (
    <S.HeaderContainer>
      {hideMenu ? (
        <SupportNumber isHeader />
      ) : (
        <div className="nav-tabs">
          <S.MenuBox onClick={disabled ? noop : toggleStatusSidebar}>
            <Icon type="menu" />
          </S.MenuBox>
        </div>
      )}

      {isDesktop && <SupportNumber isHeader />}

      <div className="nav-tabs">
        <S.LogoutWrapper>
          <S.LogoutText onClick={logout}>
            {intl.get('cashier.logout')}
          </S.LogoutText>
          {isDesktop && (
            <S.TextWrapper>{intl.get('cashier.automatic')}</S.TextWrapper>
          )}
          <ClickAwayListener onClickAway={handleStartCount}>
            <S.TextWrapper>
              {intl.get('cashier.logout_in', { time: remainingTime })}
            </S.TextWrapper>
          </ClickAwayListener>
        </S.LogoutWrapper>

        <S.LogoutIcon onClick={logout}>
          <Icon type="logout" />
        </S.LogoutIcon>
      </div>
    </S.HeaderContainer>
  );
};

CashierDashboardHeader.defaultProps = {
  hideMenu: false,
};

export default CashierDashboardHeader;
