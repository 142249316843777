import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';
import includes from 'lodash/includes';
import startsWith from 'lodash/startsWith';
import split from 'lodash/split';
import Div100vh from 'react-div-100vh';
import filter from 'lodash/filter';
import compact from 'lodash/compact';
import { parse, stringify } from 'qs';

import { useServices } from '@packages/services';

import Icon from '@components/common/iconComponents/Icon';
import RestrictedBlock from '@components/common/blockRestricted/RestrictedBlock';
import Logo from '@components/common/aircashLogo/Logo';
import SidebarLanguageSwitcher from '@components/common/sidebarLanguageSwitcher/SidebarLanguageSwitcher';
import SupportNumber from '@components/common/supportNumber/SupportNumber';

import filterByRole from '@helpers/filterByRole';
import { isDesktopView } from '@helpers/baseUtils';
import getCurrentPagePath from '@helpers/getCurrentPagePath';
import addTodayFilters from '@helpers/addTodayFilters';
import getPathWithTodayFilter from '@helpers/getPathWithTodayFilter';

import logoutUser from '@providers/user/actions/logoutUser';
import { useUserState } from '@providers/user';
import toggleStatusSidebar from '@providers/applicationContext/actions/toggleStatusSidebar';

import { LANGUAGE, TOKEN } from '../../../constants/cookies';
import RoutesMapper, { getPageUrl } from '../../../constants/routes';
import UserTypes from '../../../constants/userTypes';
import config from '../../../aircashConfig';

import * as S from './Sidebar.styled';

const {
  HOME,
  ADMIN_AUDIT,
  ADMIN_CASHIERS,
  ADMIN_DISTRIBUTORS,
  ADMIN_BALANCE,
  ADMIN_OVERVIEW,
  ADMIN_PARTNERS,
  ADMIN_POSES,
  ADMIN_PAYMENT,
  ADMIN_TRANSACTIONS,
  ADMIN_BALANCE_TRANSACTIONS,
  ADMIN_USERS,
  ADMIN_MONEY_LAUNDRY_TRANSACTIONS,
  ADMIN_CASHBOXCLOSES,
  ADMIN_FINANCIAL_STATUS,
  ADMIN_REPORTS_POS,
  ADMIN_PARTNER_REPORTS,
  ADMIN_REPORTS_DISTRIBUTOR,
  ADMIN_SETTINGS,
  ADMIN_ALL_REPORTS,
  ADMIN_STATEMENTS,
  ADMIN_MARKETING_MATERIAL,
  CASHIER_CASHBOXCLOSES,
  CASHIER_POS,
  ADMIN_QR_CODES,
  ADMIN_PARTNER_GROUPS,
  ADMIN_DASHBOARD,
} = RoutesMapper;

const { USER, PARTNER, CASHIER } = UserTypes;

const Sidebar = (): JSX.Element => {
  const { sw_aircash_url, hasReports, hasDashboard } =
    config[process.env.NODE_ENV || 'development'];
  const [current, setCurrent] = useState('');
  const { cookie } = useServices();
  const { data: user } = useUserState();
  const isDesktop = isDesktopView();
  const { push, asPath, pathname, query } = useRouter();
  const { status } = query;
  const multipleStatus = (parse(asPath) as { query }).query?.status;
  const currentLang = cookie.get(LANGUAGE) || 'de-de';

  const filterCurrentMenuItem = (path: string): string =>
    filter(split(path, '/'), (it) => !includes(it, '[id]')).join('/');

  const getOpenedSubMenu = (): string[] | null => {
    if (
      includes(asPath, getPageUrl(ADMIN_PARTNERS, user?.id)) ||
      includes(asPath, getPageUrl(ADMIN_SETTINGS, user?.id)) ||
      includes(asPath, ADMIN_STATEMENTS)
    ) {
      return ['administration'];
    }
    if (includes(asPath, 'reports')) {
      return ['reports'];
    }
    if (includes(asPath, 'test')) {
      return ['test'];
    }
    return null;
  };
  const [subMenu, setSubMenu] = useState(getOpenedSubMenu());

  useEffect(() => {
    if (getPathWithTodayFilter(pathname, multipleStatus || status)) {
      setCurrent(getPathWithTodayFilter(pathname, multipleStatus || status));
    } else if (
      (includes(asPath, user?.id) && includes(asPath, user?.type)) ||
      (includes(asPath, 'balance') && includes(asPath, 'transactions')) ||
      includes(asPath, 'settings')
    ) {
      const currentPath = getCurrentPagePath(asPath);
      setCurrent(currentPath);
    } else {
      setCurrent(filterCurrentMenuItem(pathname));
    }
  }, [asPath]);

  const logout = (): void => {
    cookie.remove(TOKEN);
    logoutUser();
    push(HOME);
  };

  const setPage = ({ key }): void => {
    if (startsWith(key, 'http')) {
      setCurrent(filterCurrentMenuItem(key));
      window.open(key, '_blank');
    } else {
      if (user?.type === CASHIER || !isDesktop) {
        toggleStatusSidebar();
      }

      push(key);
    }
  };

  const onOpenSubMenu = (openKeys: string[]): void => {
    setSubMenu(openKeys);
  };

  const navItems = [
    {
      label: intl.get('sidebar.admin'),
      key: ADMIN_OVERVIEW,
      role: [USER, PARTNER],
      icon: <Icon type="bulbOutlined" />,
    },
    {
      label: intl.get('sidebar.dashboard'),
      key: ADMIN_DASHBOARD,
      role: [USER, PARTNER],
      icon: <Icon type="dashboard" />,
    },
    {
      label: intl.get('sidebar.start'),
      key: CASHIER_POS,
      role: CASHIER,
      icon: <Icon type="house" />,
    },
    hasDashboard
      ? {
          label: intl.get('sidebar.admin'),
          key: ADMIN_PAYMENT,
          role: [CASHIER],
          icon: <Icon type="dashboard" />,
        }
      : null,
    {
      label: intl.get('sidebar.users'),
      key: ADMIN_USERS,
      role: USER,
      icon: <Icon type="users" />,
    },
    {
      label: intl.get('sidebar.distributors'),
      key: ADMIN_DISTRIBUTORS,
      role: USER,
      icon: <Icon type="distributors" />,
    },
    {
      label: intl.get('sidebar.partners'),
      key: ADMIN_PARTNERS,
      role: USER,
      icon: <Icon type="audit" />,
    },
    {
      label: intl.get('sidebar.partner_groups'),
      key: ADMIN_PARTNER_GROUPS,
      role: USER,
      icon: <Icon type="audit" />,
    },
    {
      label: intl.get('sidebar.poses'),
      key: ADMIN_POSES,
      role: [USER, PARTNER],
      icon: <Icon type="pos" />,
    },
    {
      label: intl.get('sidebar.cashiers'),
      key: ADMIN_CASHIERS,
      role: [USER, PARTNER],
      icon: <Icon type="cashier" />,
    },
    {
      label: intl.get('sidebar.balance'),
      key: ADMIN_BALANCE,
      role: PARTNER,
      icon: <Icon type="walletOutlined" />,
    },
    {
      label: intl.get('sidebar.balance_transactions'),
      key: addTodayFilters(ADMIN_BALANCE_TRANSACTIONS),
      role: [PARTNER],
      icon: <Icon type="warning" />,
    },
    {
      label: intl.get('sidebar.balance_transactions'),
      key: `${addTodayFilters(ADMIN_BALANCE_TRANSACTIONS)}&${
        multipleStatus?.$in
          ? stringify({ query: { status: multipleStatus } })
          : `status=${status ?? 'pending'}`
      }`,
      role: [USER],
      icon: <Icon type="walletOutlined" />,
    },
    {
      label: intl.get('sidebar.transactions'),
      key: addTodayFilters(ADMIN_TRANSACTIONS),
      role: [USER, PARTNER],
      icon: <Icon type="warning" />,
    },
    {
      label: intl.get('sidebar.account'),
      key: getPageUrl(ADMIN_USERS, user?.id),
      role: USER,
      icon: <Icon type="user" />,
    },
    {
      label: intl.get('sidebar.audit'),
      key: ADMIN_AUDIT,
      role: USER,
      icon: <Icon type="audit" />,
    },
    {
      label: intl.get('sidebar.cashboxcloses'),
      key: CASHIER_CASHBOXCLOSES,
      role: CASHIER,
      icon: <Icon type="user" />,
    },
    {
      label: intl.get('sidebar.money_laundry'),
      key: ADMIN_MONEY_LAUNDRY_TRANSACTIONS,
      role: CASHIER,
      icon: <Icon type="money" />,
    },
    {
      label: intl.get('sidebar.cashboxcloses'),
      key: addTodayFilters(ADMIN_CASHBOXCLOSES),
      role: PARTNER,
      icon: <Icon type="audit" />,
    },
    hasReports
      ? {
          key: 'reports',
          label: intl.get('sidebar.reports'),
          icon: <Icon type="cashier" />,
          role: USER,
          children: [
            {
              label: intl.get('sidebar.financial_status'),
              key: addTodayFilters(ADMIN_FINANCIAL_STATUS),
            },
            {
              label: intl.get('sidebar.all_transactions'),
              key: addTodayFilters(ADMIN_ALL_REPORTS),
            },
            {
              label: intl.get('sidebar.reports_distributors'),
              key: addTodayFilters(ADMIN_REPORTS_DISTRIBUTOR),
            },
            {
              label: intl.get('sidebar.reports_partners'),
              key: addTodayFilters(ADMIN_PARTNER_REPORTS),
            },
            {
              label: intl.get('sidebar.reports_pos'),
              key: addTodayFilters(ADMIN_REPORTS_POS),
            },
          ],
        }
      : null,
    {
      key: 'administration',
      label: intl.get('sidebar.administration'),
      icon: <Icon type="highlight" />,
      role: PARTNER,
      children: [
        {
          label: intl.get('sidebar.company'),
          key: getPageUrl(ADMIN_PARTNERS, user?.id),
          icon: <Icon type="user" />,
        },
        {
          label: intl.get('sidebar.settings'),
          key: getPageUrl(ADMIN_SETTINGS, user?.id),
        },
        {
          label: intl.get('sidebar.statements'),
          key: addTodayFilters(ADMIN_STATEMENTS),
        },
      ],
    },
    {
      label: intl.get('sidebar.marketing_material'),
      key: ADMIN_MARKETING_MATERIAL,
      icon: <Icon type="highlight" />,
      role: PARTNER,
    },
    {
      label: intl.get('sidebar.tutorial_partner'),
      key: `${sw_aircash_url}/tutorial/`,
      role: PARTNER,
      icon: <Icon type="tutorial" />,
    },
    {
      label: intl.get('sidebar.tutorial_cashier'),
      key: `${sw_aircash_url}/tutorial-mitarbeiter/`,
      role: [PARTNER, CASHIER],
      icon: <Icon type="tutorial" />,
    },
    {
      label: intl.get('sidebar.faq'),
      key: `${sw_aircash_url}/faq/`,
      role: CASHIER,
      icon: <Icon type="user" />,
    },
    {
      label: intl.get('sidebar.qr_codes'),
      key: ADMIN_QR_CODES,
      role: CASHIER,
      icon: <Icon type="qr_another" />,
    },
  ];

  const renderNavMenu = (): JSX.Element => {
    return (
      <S.NavMenu
        mode="inline"
        onClick={setPage}
        selectedKeys={[current, currentLang]}
        openKeys={subMenu}
        onOpenChange={onOpenSubMenu}
        items={filterByRole(compact(navItems))}
      />
    );
  };

  const menuContent = (): JSX.Element => (
    <>
      <S.MenuSection>
        {renderNavMenu()}

        {!isDesktop && (
          <RestrictedBlock userRole={[CASHIER]}>
            <SupportNumber />
          </RestrictedBlock>
        )}

        <RestrictedBlock userRole={[CASHIER]}>
          <SidebarLanguageSwitcher />
        </RestrictedBlock>
      </S.MenuSection>

      <S.LogoutWrapper onClick={logout}>
        <S.LogoutIcon>
          <Icon type="logout" />
        </S.LogoutIcon>
        {intl.get('cashier.logout')}
      </S.LogoutWrapper>
    </>
  );

  return (
    <>
      <RestrictedBlock userRole={[USER, PARTNER]}>
        <Div100vh className="no-print">
          <S.Wrapper>
            <S.Root>
              <S.LogoWrapper>
                <Logo />
              </S.LogoWrapper>

              {menuContent()}
            </S.Root>
          </S.Wrapper>
        </Div100vh>
      </RestrictedBlock>

      <RestrictedBlock userRole={[CASHIER]}>
        <S.CashierMenu className="no-print">{menuContent()}</S.CashierMenu>
      </RestrictedBlock>
    </>
  );
};

export default Sidebar;
