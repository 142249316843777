export const checkServer = (): boolean =>
  Object.prototype.toString.call(global.process) === '[object process]';

export const checkBrowser = (): boolean => !checkServer();

export const compareColumns =
  (column) =>
  (a, b): number => {
    if (
      (String(a[column]) === 'true' || String(a[column]) === 'false') &&
      (String(b[column]) === 'true' || String(b[column]) === 'false')
    ) {
      return a[column] - b[column];
    }
    // eslint-disable-next-line
    if (Number(a[column]) == a[column] && Number(b[column]) == b[column]) {
      return a[column] - b[column];
    }

    return (a[column] || '').localeCompare(b[column] || '');
  };

export const copyToClipboard = (text: string): void => {
  navigator?.clipboard?.writeText(text);
};

export const isDesktopView = (): boolean => {
  try {
    return window.innerWidth > 1024;
  } catch (error) {
    return false;
  }
};

export const smallMobileScreen = (): boolean => {
  try {
    return window.innerWidth <= 320;
  } catch (error) {
    return false;
  }
};

export const togglePresent = (arr, el): number[] => {
  const idx = arr.indexOf(el);
  if (idx >= 0) {
    arr.splice(idx, 1);
  } else {
    arr.push(el);
  }
  return arr;
};
