import styled from 'styled-components';
import Menu from 'antd/lib/menu';

import device from '@helpers/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  width: 250px;
  height: 100%;

  @media ${device.tablet} {
    width: 300px;
  }
`;

export const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: inherit;
  height: 100%;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--surface-bg);
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
  z-index: 10;
  font-family: Roboto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LogoWrapper = styled.div`
  padding: 15px 10px 15px 20px;
`;

export const CashierMenu = styled(Root)`
  top: 70px;
  height: calc(100vh - 70px);
  width: 100%;
  left: 0;
  right: 0;
  z-index: 11;

  @media ${device.tablet} {
    top: 54px;
    height: 100%;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 16px !important;
  }

  .ant-menu-sub .ant-menu-item {
    padding-left: 48px !important;
  }
`;

export const MenuSection = styled.div`
  flex-grow: 1;
  width: 100%;
`;

export const NavMenu = styled(Menu)`
  border: none;

  &.ant-menu-vertical .ant-menu-item-selected.ant-menu-item::after {
    transform: none;
    opacity: 1;
  }
`;

export const LogoutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  min-height: 40px;
  width: 100%;
  box-shadow: inset 0 1px 0 0 #f0f0f0;
  cursor: pointer;
  color: #f00;
  font-size: 14px;
  line-height: 1.4;
`;

export const LogoutIcon = styled.div`
  margin-right: 5px;

  span[role='img'] > svg {
    width: 15px;
    height: 15px;
    color: #f00;
  }
`;
