import { ColumnsType } from 'antd/lib/table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

import { navItem } from '@components/common/posSubmenu/Submenu.types';

import { useUserState } from '@providers/user';

const filterByRole = (
  colection: ColumnsType | ItemType,
): ColumnsType | ItemType | navItem[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: user } = useUserState();

  return filter(
    colection,
    ({ role }) => role === 'all' || includes(role, user?.type),
  );
};

export default filterByRole;
