import split from 'lodash/split';
import includes from 'lodash/includes';
import find from 'lodash/find';

export default (asPath: string, withoutPreview?: boolean): string => {
  const path = split(asPath, '?')[0];
  const query = split(asPath, '?')[1];
  const previewQuery = find(split(query, '&'), (el) => includes(el, 'preview'));
  const testQuery = 'testPage=true';

  if (includes(query, testQuery)) {
    return previewQuery
      ? `${path}?${testQuery}&${previewQuery}`
      : `${path}?${testQuery}`;
  }

  return previewQuery && !withoutPreview ? `${path}?${previewQuery}` : path;
};
