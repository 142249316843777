import React, { Fragment } from 'react';
import includes from 'lodash/includes';

import { useUserState } from '@providers/user';

import PropTypes from './RestrictedBlock.types';

const RestrictedBlock = (props: PropTypes): JSX.Element | null => {
  const { data: user } = useUserState();
  let show = null;
  const { userRole, children } = props;

  show = includes(userRole, user?.type);

  return show ? <Fragment>{children}</Fragment> : null;
};
export default RestrictedBlock;
