import React from 'react';
import intl from 'react-intl-universal';

import * as S from './SupportNumber.styled';

const SupportNumber = ({ isHeader }: { isHeader?: boolean }): JSX.Element => (
  <S.Wrapper isHeader={isHeader}>
    {intl.get('common.support_hotline')}
    <S.Number href="tel:+4989215255567" isHeader={isHeader}>
      089/215255567
    </S.Number>
  </S.Wrapper>
);

SupportNumber.defaultProps = {
  isHeader: false,
};

export default SupportNumber;
