import React from 'react';
import { Empty } from 'antd';
import intl from 'react-intl-universal';

const CustomEmpty = (): JSX.Element => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={intl.get('common.no_data')}
  />
);

export default CustomEmpty;
