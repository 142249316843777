import split from 'lodash/split';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import { stringify } from 'qs';

import RoutesMapper from '../constants/routes';

import addTodayFilters from './addTodayFilters';

const {
  ADMIN_BALANCE_TRANSACTIONS,
  ADMIN_TRANSACTIONS,
  ADMIN_CASHBOXCLOSES,
  ADMIN_FINANCIAL_STATUS,
  ADMIN_REPORTS_DISTRIBUTOR,
  ADMIN_PARTNER_REPORTS,
  ADMIN_REPORTS_POS,
  ADMIN_STATEMENTS,
  ADMIN_ALL_REPORTS,
} = RoutesMapper;

const keysWithTodayFilter = [
  ADMIN_BALANCE_TRANSACTIONS,
  ADMIN_TRANSACTIONS,
  ADMIN_CASHBOXCLOSES,
  ADMIN_FINANCIAL_STATUS,
  ADMIN_ALL_REPORTS,
  ADMIN_REPORTS_DISTRIBUTOR,
  ADMIN_PARTNER_REPORTS,
  ADMIN_REPORTS_POS,
  ADMIN_STATEMENTS,
];

export default (
  pathname: string,
  params?: string | string[] | { $in: string[] },
  key?: string,
): string | null => {
  const filterCurrentMenuItem = (path: string): string =>
    filter(split(path, '/'), (it) => !includes(it, '[id]')).join('/');

  if (includes(keysWithTodayFilter, filterCurrentMenuItem(pathname))) {
    const currentPath = addTodayFilters(filterCurrentMenuItem(pathname));

    if (key && params) {
      return (params as { $in: string[] }).$in
        ? `${currentPath}&${stringify({ query: { [key]: params } })}`
        : `${currentPath}&${key}=${params}`;
    }

    if (params) {
      return (params as { $in: string[] }).$in
        ? `${currentPath}&${stringify({ query: { status: params } })}`
        : `${currentPath}&status=${params}`;
    }

    return currentPath;
  }

  return null;
};
