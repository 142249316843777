import styled from 'styled-components';
import Menu from 'antd/lib/menu';
import Avatar from 'antd/lib/avatar';

import device from '@helpers/breakpoints';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .nav-tabs:last-child {
    margin-left: auto;
  }

  .nav-tabs.left-section {
    flex-grow: 1;
  }

  @media ${device.tablet} {
    padding: 5px;
    min-width: calc(100vw - 80px);
    overflow-x: hidden;

    .nav-tabs.left-section {
      flex-grow: 0;
    }
  }
`;

export const NavMenu = styled(Menu)`
  height: 100%;
  flex-grow: 1;
  background-color: transparent;
  color: var(--surface-bg);
  border-bottom: none;

  li[role='menuitem']:hover,
  li[role='menuitem']:focus,
  li[role='menuitem']:active,
  div[role='menuitem']:hover,
  div[role='menuitem']:focus,
  div[role='menuitem']:active,
  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-submenu-active {
    color: var(--primary-button) !important;
  }

  .ant-menu-item-selected::after,
  .ant-menu-item-active::after {
    border-bottom: 2px solid var(--primary-button) !important;
  }

  @media ${device.tablet} {
    flex-grow: 0;
    margin-left: 0;
    border: 1px solid;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: var(--primary-button);
    }

    &:hover span[role='img'] {
      color: var(--primary-button);
    }

    .ant-menu-submenu-horizontal.ant-menu-submenu-selected {
      color: var(--surface-bg);
    }
  }
`;

export const LinkST = styled.a`
  color: var(--surface-bg);
  text-decoration: none;

  &:hover {
    color: var(--primary-button);
  }
`;

export const UserAvatar = styled(Avatar)`
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--surface-bg);

  .ant-avatar-string {
    top: 50%;
    transform: scale(1) translate(-50%, -50%) !important;
  }
`;

export const Logo = styled.img`
  height: 30px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const LogoutWrapper = styled.div`
  margin-right: 10px;
  font-family: Roboto;
  font-size: 12px;
  color: var(--surface-bg);
  text-align: right;
`;

export const LogoutText = styled.p`
  margin: 0;
  font-size: 14px;
  color: var(--primary-button);
  line-height: normal;
  text-transform: uppercase;
`;

export const TextWrapper = styled.span`
  margin: 0;
  font-size: 12px;
  color: var(--surface-bg);
  line-height: normal;
  display: inline;
`;

export const LogoutIcon = styled.div`
  display: flex;
  cursor: pointer;

  span[role='img'] > svg {
    width: 32px;
    height: 32px;
    color: var(--primary-button);
  }
`;

export const MenuBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--surface-bg);
  border-radius: 50%;
  cursor: pointer;
`;
