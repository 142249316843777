import { stringify } from 'qs';

import { dateTodayRange } from '@helpers/dateFormat';

const addTodayFilters = (resource: string): string => {
  const { start, end } = dateTodayRange();
  const query = {
    query: {
      createdAt: {
        $gte: start,
        $lte: end,
      },
    },
  };

  return `${resource}?${stringify(query)}`;
};

export default addTodayFilters;
