import React from 'react';

import { useServices } from '@packages/services';

import { LANGUAGE } from '../../../constants/cookies';

import * as S from './SidebarLanguageSwitcher.styled';

const SidebarLanguageSwitcher = (): JSX.Element => {
  const { cookie } = useServices();
  const lang = cookie.get(LANGUAGE);

  const setLocale = (key): void => {
    cookie.set(LANGUAGE, key);
    document.location.reload();
  };

  return (
    <S.LanguageSelect
      defaultValue={lang || 'de-de'}
      onChange={setLocale}
      options={[
        {
          title: 'English',
          value: 'en-us',
        },
        {
          title: 'Deutsch',
          value: 'de-de',
        },
      ]}
    />
  );
};

export default SidebarLanguageSwitcher;
