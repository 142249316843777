import styled from 'styled-components';
import { Select } from 'antd';

import { fieldHeight, fonts } from '@helpers/styleVariables';

const StyledSelect = styled(Select)`
  position: relative;
  width: 100%;
  height: ${fieldHeight.small};
  font-family: Roboto;
  font-size: ${fonts.small};
  color: var(--text);
  background-color: var(--surface-bg);
  border-radius: 5px;

  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  .ant-select-selector,
  .ant-select-selector .ant-select-selection-search-input {
    height: 100% !important;
  }

  .ant-select-selector {
    border-radius: 5px !important;
    overflow: hidden;
  }

  &.ant-select-selector:hover .ant-select-selector,
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: var(--primary-button) !important;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 25%);
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    overflow: hidden;
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  &.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 15px);
  }

  & svg {
    width: 16px;
    height: 12px;
    fill: var(--disabled);
  }

  .ant-empty {
    margin: 8px 0;

    .ant-empty-image {
      margin-bottom: 5px;
      height: 35px;
    }
  }
`;

export default StyledSelect;
