export default {
  development: {
    sw_aircash_url: 'https://sw-aircash.tb.exxs.net',
    gwg_training_url: 'https://sw-aircash.tb.exxs.net',
    loginedTime: 4 * 60 * 60 * 1000, // 4 hours
    hasReports: true,
    hasDashboard: true,
    hasWithdrawal: true,
    deposits: {
      min: 250,
      max: 7500,
    },
    testUsers: ['denis@bergx2.de', 'yana.kyrpenko@gmail.com'],
    testUserNumbers: '+380637070145+48666216905+380637070145+380636176368',
  },
  production: {
    sw_aircash_url: 'https://partner-training.swcash.de',
    gwg_training_url: 'https://partner-training.swcash.de',
    loginedTime: 4 * 60 * 60 * 1000, // 4 hours
    hasReports: true,
    hasDashboard: false,
    hasWithdrawal: true,
    deposits: {
      min: 250,
      max: 7500,
    },
    testUsers: ['denis@bergx2.de', 'yana.kyrpenko@gmail.com'],
    testUserNumbers: '+380637070145+48666216905+380637070145+380636176368',
  },
};
