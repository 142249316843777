import React, { useRef, useEffect, useState } from 'react';
import { Select } from 'antd';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import isString from 'lodash/isString';

import Icon from '@components/common/iconComponents/Icon';

import UserTypes from '../../../constants/userTypes';
import CustomEmpty from '../customEmpty/CustomEmpty';

import { ISelect, TOptionsGroup } from './SelectInput.types';
import StyledSelect from './SelectInput.styled';

const { Option, OptGroup } = Select;
const { USER, CASHIER } = UserTypes;

const preventListScroll = (e): false => {
  e.preventDefault();
  e.stopPropagation();

  return false;
};

const CSS_LIST_SELECTOR = '.atn-selector-open .rc-virtual-list-holder';

const SelectInput = (props: ISelect): JSX.Element => {
  const {
    showSearch,
    defaultValue,
    value,
    options,
    optionsGroup,
    mode,
    disabled,
    className,
    allowClear,
    onChange,
    onFocus,
    placeholder,
    userRole,
  } = props;
  const inputRef = useRef(null);
  const [isOpenList, setIsOpenList] = useState(false);

  useEffect(() => {
    if (isOpenList) {
      document
        .querySelector(CSS_LIST_SELECTOR)
        ?.addEventListener('scroll', preventListScroll, { passive: false });
    } else {
      document
        .querySelector(CSS_LIST_SELECTOR)
        ?.removeEventListener('scroll', preventListScroll);
    }
  }, [isOpenList]);

  useEffect(() => {
    document
      .querySelector(CSS_LIST_SELECTOR)
      ?.removeEventListener('scroll', preventListScroll);
  }, []);

  const handleChange = (val: string): void => {
    if (onChange) {
      onChange(val);
    }
    setIsOpenList(false);
    inputRef.current.blur();
  };

  const renderOptions = (optionList): JSX.Element => {
    return map(optionList, (option) => (
      <Option key={option.value} value={option.value}>
        {option.title}
      </Option>
    ));
  };

  const renderGroupOptions = (optionGroupList: TOptionsGroup): JSX.Element => {
    return map(optionGroupList, (optionGroup) => (
      <OptGroup label={optionGroup.label} key={optionGroup.label}>
        {renderOptions(optionGroup.values)}
      </OptGroup>
    ));
  };

  const handleFilterOption = (input: string, option): boolean => {
    let optionValue = option.children;
    if (!isString(option.children)) {
      const { children } = option.children.props;
      const [name] = children;
      optionValue = name;
    }
    return includes(toLower(optionValue), toLower(input));
  };

  return (
    <StyledSelect
      ref={inputRef}
      showSearch={showSearch}
      defaultValue={defaultValue}
      value={value}
      mode={mode}
      onMouseEnter={(): void => setIsOpenList(true)}
      onMouseLeave={(): void => setIsOpenList(false)}
      disabled={disabled}
      className={className}
      allowClear={allowClear}
      /* prettier-ignore */
      suffixIcon={(
        <Icon
          type={`${userRole === CASHIER ? 'arrow-up-fill' : 'arrow-down'}`}
        />
      )}
      onChange={handleChange}
      onFocus={onFocus}
      filterOption={handleFilterOption}
      placeholder={placeholder}
      getPopupContainer={(trigger): (() => void) => trigger.parentNode}
      /* prettier-ignore */
      notFoundContent={(<CustomEmpty />)}
    >
      {options && renderOptions(options)}
      {optionsGroup && renderGroupOptions(optionsGroup)}
    </StyledSelect>
  );
};

SelectInput.defaultProps = {
  options: undefined,
  optionsGroup: undefined,
  mode: undefined,
  showSearch: false,
  disabled: false,
  onFocus: undefined,
  onSearch: undefined,
  className: '',
  allowClear: false,
  placeholder: '',
  userRole: USER,
};

export default SelectInput;
