import styled from 'styled-components';

export const LogoTitle = styled.h1`
  margin: 0 0 0 5px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
  color: var(--primary-text);
  text-transform: uppercase;
`;

export const LogoLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  height: 30px;
`;

export const Role = styled.span`
  text-transform: capitalize;
`;
